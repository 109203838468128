import {
	ComponentType,
	CSSProperties,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react'
import { motion } from 'framer-motion'
import { NavbarProps, NavigationType } from './Navigation.types'
import {
	useDevice,
	useEventListener,
	useIsomorphicLayoutEffect,
	useScrollLock,
} from 'common'
import { AbstractButton } from '../../atoms'
import { useRouter } from 'next/compat/router'
import clsx from 'clsx'
import throttle from 'lodash.throttle'
import debounce from 'lodash.debounce'
import styles from './Navigation.module.css'
import { HamburgerButton } from '../HamburgerButton/HamburgerButton'
import { Dropdown } from './Dropdown'
import MobileNavigation from './MobileNavigation'

type NavigationProps = NavbarProps & {
	linkComponent?: ComponentType<any>
	children?: ({
		handleClick,
		dropdownVisible,
	}: {
		handleClick: (dropdown: number | 'profile') => void
		dropdownVisible: number | 'profile' | null
	}) => JSX.Element
}

export const Navigation = ({
	children,
	linkComponent: LinkComponent = AbstractButton,
	headerLinkGroups = [],
}: NavigationProps) => {
	const { events, push } = useRouter() ?? {}
	const itemsRef = useRef<HTMLUListElement>(null)
	const itemRef = useRef<HTMLDivElement>(null)
	const timeoutRef = useRef<ReturnType<typeof setTimeout>>()

	const device = useDevice()
	const isMobile = device === 'mobile'
	const isTablet = device === 'tablet'
	const isDesktop = device === 'desktop'

	const [topBarHeight, setTopBarHeight] = useState(0)
	const [dropdownVisible, setDropdownVisible] = useState<
		number | 'profile' | null
	>(isMobile ? 0 : null)

	const isLoginVisible = dropdownVisible === 'profile'
	const isDropdownVisible = dropdownVisible !== null && !isLoginVisible

	const navigation: Array<NavigationType> = headerLinkGroups.map(
		({ button_text, homepage_link_text, homepage_link_url, link_groups }) => {
			return {
				title: button_text,
				homepageLabel: homepage_link_text,
				homepageUrl: homepage_link_url,
				linkGroups: link_groups,
			}
		}
	)

	const lock = isMobile ? isDropdownVisible || isLoginVisible : false

	useScrollLock(lock)

	const handleMouseEnter = useCallback(
		(currentIndex: number | 'profile') => {
			if (isDesktop) {
				clearTimeout(timeoutRef.current)
				setDropdownVisible(currentIndex)
			}
		},
		[isDesktop]
	)

	const handleClick = useCallback(
		(currentIndex: number | 'profile') => {
			setDropdownVisible((state) => {
				// Close profile dropdown if already opened
				if (state === 'profile' && currentIndex === 'profile') {
					return null
				}

				// Close dropdown if already opened on tablet
				if (isTablet && state === currentIndex) {
					return null
				}

				return currentIndex
			})
		},
		[isTablet]
	)

	const handleMouseLeave = useCallback(() => {
		if (isDesktop) {
			timeoutRef.current = setTimeout(() => {
				setDropdownVisible(null)
			}, 200)
		}
	}, [isDesktop])

	const handleToggleNavigation = useCallback(() => {
		setDropdownVisible((state) => {
			if (state === null || state === 'profile') {
				return 0
			}
			return null
		})
	}, [])

	const handleScroll = useCallback(() => {
		const prevScroll = 0
		const { scrollTop } = document.documentElement

		if (isTablet && prevScroll !== scrollTop) {
			setDropdownVisible(null)
		}
	}, [isTablet])

	const setViewportProperty = useCallback(() => {
		/* https://css-tricks.com/the-trick-to-viewport-units-on-mobile/ */
		const vh = window.innerHeight * 0.01
		document.documentElement.style.setProperty('--vh', `${vh}px`)
	}, [])

	useEventListener(
		'scroll',
		throttle(handleScroll, 200),
		undefined,
		undefined,
		[isTablet]
	)

	useEventListener('resize', debounce(setViewportProperty, 200))

	useIsomorphicLayoutEffect(setViewportProperty, [setViewportProperty])

	useIsomorphicLayoutEffect(() => {
		let observer: ResizeObserver | undefined

		try {
			observer = new ResizeObserver(([entry]) => {
				if (!entry) {
					return
				}

				setTopBarHeight(entry.contentRect.height)
			})
		} catch {
			/* empty */
		}

		if (!observer) {
			return
		}

		let emergencyTopBar: HTMLElement | null = null

		const timeout = setTimeout(() => {
			emergencyTopBar = document.querySelector(
				'.js-emergency-notification-bar'
			) as HTMLElement | null

			if (!emergencyTopBar) {
				return
			}

			const { clientHeight } = emergencyTopBar

			setTopBarHeight(clientHeight)

			observer?.observe(emergencyTopBar)
		}, 3000)

		return () => {
			clearTimeout(timeout)
			if (emergencyTopBar) {
				observer?.unobserve(emergencyTopBar)
			}
		}
	}, [isMobile])

	useEffect(() => {
		if (!events) {
			return
		}

		const handleRouteChange = () => {
			setDropdownVisible(null)
		}

		events.on('hashChangeStart', handleRouteChange)
		events.on('routeChangeStart', handleRouteChange)
		return () => {
			events.off('hashChangeStart', handleRouteChange)
			events.off('routeChangeStart', handleRouteChange)
		}
	}, [events, isDropdownVisible])

	return (
		<>
			<div
				className={clsx(
					'bg-black-alpha/[0.32] absolute left-0 right-0 top-full h-screen backdrop-blur',
					!isLoginVisible && !isDropdownVisible && 'hidden',
					isDropdownVisible && 'sm:hidden'
				)}
				onClick={() => setDropdownVisible(null)}
			/>
			{navigation.length > 0 && (
				<>
					<HamburgerButton
						handleToggleNavigation={handleToggleNavigation}
						isDropdownVisible={isDropdownVisible}
					/>

					<nav
						style={
							{
								'--navigation-offset': `${topBarHeight + 72}px`,
							} as CSSProperties
						}
						className={clsx(
							'sm:absolute sm:left-0 sm:top-full sm:w-full sm:bg-white',
							styles.navigation,
							isDropdownVisible ? 'sm:block' : 'sm:hidden'
						)}
					>
						<ul
							ref={itemsRef}
							className="flex items-center sm:h-full sm:w-full sm:flex-col"
						>
							{isMobile ? (
								<MobileNavigation navigationData={navigation} />
							) : (
								navigation.map(({ title, ...rest }, index) => {
									const isActive = dropdownVisible === index

									const handleItemClick = () => {
										handleClick(index)
										if (!isMobile && isActive && rest.homepageUrl) {
											push?.(rest.homepageUrl)
										}
									}
									return (
										<motion.li
											key={title}
											initial={{
												opacity: 0,
											}}
											animate={{
												opacity: 1,
												transition: {
													type: 'tween',
													duration: 0.3,
													delay: 0.15 + index * 0.15,
												},
											}}
											className="sm:py-10"
										>
											<div
												ref={itemRef}
												{...(!isMobile && {
													onMouseEnter: () => handleMouseEnter(index),
													onMouseLeave: handleMouseLeave,
												})}
											>
												<LinkComponent
													className={clsx(
														'p_highlight sm:bg-gray-05 rounded-full px-5 py-3 transition-colors sm:px-6',
														isActive && 'bg-gray-05 text-primary'
													)}
													onClick={handleItemClick}
												>
													{title}
												</LinkComponent>
												{isActive && <Dropdown {...rest} />}
											</div>
										</motion.li>
									)
								})
							)}
						</ul>
					</nav>
				</>
			)}
			{children && children({ handleClick, dropdownVisible })}
		</>
	)
}
